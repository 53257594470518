/* Ensure the body and root fill the viewport */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 300; /* Thin weight for elegance */
  text-transform: uppercase;
  color: #323232;
}

p, td, div{
  font-family: "Poppins", sans-serif;
  font-weight: 300; /* Thin weight for elegance */
  /* text-transform: uppercase; */
  line-height: 170%;
}

/* Ensure the main app container grows to fill space */
#root, .app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Make sure it fills the entire viewport */
}

/* Main content should take up all available space */
.main-content {
  flex: 1; /* This pushes the footer to the bottom */
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, 
  hsl(0, 0%, 46%) 0%,   /* very light with a subtle blue-green tint */
  hsl(0, 0%, 48%) 40%,  /* light gray with slight tint */
  hsl(0, 0%, 30%) 70%,  /* muted tone with a hint of color */
  hsl(0, 0%, 11%) 100%  /* muted accent: less saturated teal */
  );
}

/* Footer stays at the bottom */
.footer-container {
  width: 100%;
  background-color: #222;
  color: #bbb;
  text-align: center;
  padding: 20px;
  margin-top: auto; /* Pushes footer to bottom when content is short */
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.header-container, .footer-container {
  width: 100%;
  background-color: #000 !important;
  color: #fff;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
  box-sizing: border-box;
}
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 50px;
}

.beta-label {
  background-color: #777; /* Or any color you prefer */
  color: #fff;
  font-size: 0.75em;
  padding: 4px 8px;
  border-radius: 12px;
  margin-left: 10px;
  align-content: center;
  width:58px;
}

.footer-container {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.container {
  display: flex;
  padding: 20px;
  background-color: #fff;
}

.video-map-column {
  flex: 1;
  margin-right: 20px;
}

.youtube-url-input {
  margin-bottom: 20px;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navigation li {
  margin-left: 20px;
}

.navigation a {
  color: #fff;
  text-decoration: none;
}

.button-segment-creator {
  margin-left: 10px;
  color: #fff;
  background-color: #9c9c9c;
  border: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.segment-creator-text {
  color: #666666 ;
  font-size: .8em;
  font-weight: bold;
}
.segment-creator-input {
  font-size: .8em;
  font-weight: bold;
  margin-top: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-style: dotted;
  border-width: 0px 0px 2px 0px;
  border-color: #dddddd;
  width: 60px;
}

.custom-flag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid white;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
}


.metrics{
  color: #666666 ;
  font-size: .8em;
}

.footer-container {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #bbb;
}

.footer-navigation {
  margin-top: 10px;
}

.footer-link {
  color: #cccccc; /* Bright grey */
  text-decoration: none;
  font-weight: 500;
}

.footer-link:hover {
  color: #ffffff; /* Even brighter grey/white on hover */
  text-decoration: underline;
}

/* Ensure the modal covers the map completely */


/* Push the Leaflet attribution down */
.leaflet-control-attribution {
  z-index: 0 !important; /* Ensure it is behind the modal */
  position: relative;
}



/* Search Input */
.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Table */
.run-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.run-table th,
.run-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.run-table th {
  cursor: pointer;
  background: #f4f4f4;
}

.run-table th:hover {
  background: #e0e0e0;
}



.delete-btn {
  padding: 8px 12px;
  background-color: #13A1A8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/* Save Run Section - Full Width */
.save-run {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  background: white;
  border-radius: 8px;
  border: 2px solid #ddd;
  width: 100%; /* Full width of parent */
  box-sizing: border-box;
}

/* Styled Input - Same Width as Other Elements */
.save-run-input {
  flex: 2; /* Allows input to take up most space */
  font-size: 0.85em;
  font-weight: bold;
  padding: 8px 10px;
  border-style: dotted;
  border-width: 0px 0px 2px 0px;
  border-color: #ddd;
  width: 100%;
}

/* Checkbox Styling */
.save-run-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.85em;
  font-weight: bold;
  white-space: nowrap;
}

/* Buttons Row - Align to Right */
.save-run-btn {
  padding: 8px 12px;
  background-color: #13A1A8;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  white-space: nowrap; /* Prevent button text from wrapping */
}

.save-run-btn:hover {
  background-color: #0056b3;
}

/* Secondary Button for Extract GPS */
.save-run-btn.secondary {
  background-color: #6c757d;
}

.save-run-btn.secondary:hover {
  background-color: #5a6268;
}

/* Message Styling */
.save-run-message {
  font-size: 0.85em;
  color: #d9534f;
  font-weight: bold;
}

/* Wider container for better readability */
.buy-credits-container {
  width: 600px;
  max-width: 600px;
  margin: 50px auto;
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Title and Subtitle Styling */
.buy-credits-title {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: bold;
}

.buy-credits-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

/* Credit Option Styling */

.buy-credits-title {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Styled Card Look */
.credit-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.credit-option:hover {
  transform: scale(1.03);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
}

.credit-icon {
  font-size: 24px;
  color: #f5a623;
  margin-right: 12px;
}

.credit-price {
  font-weight: bold;
}

/* Styled Buttons */
.buy-button, .back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #13A1A8;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.2s, transform 0.1s;
}

.buy-credits-details {
  display: flex;
  align-items: center;
  gap: 5px; /* Ensures a small gap between elements */
}

.buy-button:hover {
  background: #0056b3;
  transform: scale(1.05);
}

/* Back Button Styling */
.back-button {
  background: #6c757d;
  margin-top: 20px;
}

.back-button:hover {
  background: #5a6268;
  transform: scale(1.05);
}

.button-icon {
  font-size: 16px;
}

/* Checkout Page Container */
.checkout-container {
  width: 600px;
  max-width: 600px;
  margin: 50px auto;
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Title & Subtitle */
.checkout-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}

.checkout-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

/* Card Input Container */
.card-input {
  background: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* CardElement */
.card-element {
  padding: 10px;
  font-size: 16px;
}

/* Buy Now Button */
.buy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #13A1A8;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.2s, transform 0.1s;
}

.buy-button:hover {
  background: #0056b3;
  transform: scale(1.05);
}


/* Checkout Buttons Row */
.checkout-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Back Button */
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #6c757d;
  padding: 10px 16px;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background 0.2s, transform 0.1s;
}

.back-button:hover {
  background: #5a6268;
  transform: scale(1.05);
}

/* Icon in the Back Button */
.button-icon {
  font-size: 16px;
}

/* TOOLTIP */

.tooltip-container {
  display: inline-block;
  cursor: pointer;
}

.tooltip-icon {
  margin-left: 5px;
  color: #555;
  font-size: 16px;
  cursor: pointer;
}

/* Modal Overlay */
.tooltip-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

/* Modal Content */
.tooltip-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal Header */
.tooltip-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Close Button */
.tooltip-close-btn {
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.tooltip-close-btn:hover {
  color: #000;
}

/* Modal Body */
.tooltip-modal-body {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
}


.parent-scroll-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.parent-scroll-container > .dashboard-item {
  flex: 0 0 auto;
  min-width: 800px;
  margin-right: 20px;
}

/* Container */
.login-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Header styles */
.login-header {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.login-icon {
  font-size: 48px;
  color: #FE6C62; /* Orange color for the big icon */
}

.signup-icon {
  /* Optionally adjust for sign-up icon if needed */
}

.login-title {
  margin: 10px 0;
}

.login-description {
  color: #555;
  font-size: 0.9em;
}

/* Error message */
.error-text {
  color: red;
  margin-bottom: 10px;
}

/* Input fields */
.login-input {
  display: block;
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}



/* Switch link */
.login-switch {
  color: #555;
  text-align: center;
}

.login-switch-link {
  color: #13A1A8;
  cursor: pointer;
  text-decoration: underline;
}

.fullscreen-object {
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
}

/* Homepage Layout */
.homepage-container {
  display: flex;
  min-height: 100vh;
  font-family: sans-serif;
}

/* Sidebar (30% width) */
.homepage-sidebar {
  width: 30%;
  background: #f7f7f7;
  padding: 30px 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

/* Main Content (70% width) */
.homepage-main {
  width: 70%;
  padding: 30px 20px;
}

/* User Panel */
.user-panel {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
}
.user-icon {
  width: 80px;
  height: 80px;
  background: #13A1A8;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  padding: 20px;
}
.user-info h3 {
  margin: 0;
  font-size: 1.4em;
  padding: 5px;
}
.user-info p {
  margin: 5px 0;
  font-size: 0.95em;
  padding: 5px;
}

/* Buttons */
.primary-btn {
  background: #c1d639;
  color: #000;
  border: none;
  border-radius: 4px;
  padding: 12px;
  width: auto;
  margin-top: 15px;
  cursor: pointer;
  font-size: 1em;
}
.primary-btn:hover {
  background: #119a9e;
}
.secondary-btn {
  background: #119a9e;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px;
  width: auto;
  margin-top: 15px;
  cursor: pointer;
  font-size: 1em;
}
.secondary-btn:hover {
  background: #c9302c;
}

/* Big Action Button */
.big-action-btn {
  background: #13A1A8;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 20px;
  font-size: 1.4em;
  width: 100%;
  cursor: pointer;
  margin-bottom: 30px;
}

/* Runs Search & Sort */
.search-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 15px;
}

/* Runs Table */
.run-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.run-table th {
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
  background: #f4f4f4;
}
.run-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.run-table th,
.run-table td {
  border-left: none;
  border-right: none;
}

/* Run Name cell: Thumbnail and name */
.run-name-cell {
  display: flex;
  align-items: center;
}
.run-thumb {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}
.placeholder-thumbnail {
  width: 80px;
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;
}

/* Select Button */
.select-btn {
  padding: 6px 12px;
  background: #FE6C62;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.select-btn:hover {
  background: #e55c53;
}

/* FAQ Styles */
.faq-list {
  list-style: none;
  padding: 0;
}
.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}
.faq-question {
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.15em;
  line-height: 1.3em;
}
.faq-answer {
  margin-top: 10px;
  padding-left: 25px;
  line-height: 1.5em;
  font-size: 1em;
  color: #555;
}

/* Section Headings */
.homepage-main h2 {
  margin-bottom: 15px;
  font-size: 1.5em;
}

/* Ensure mobile and desktop versions are toggled correctly */
.desktop-only {
  display: flex;
}

.mobile-only,
.mobile-menu-overlay {
  display: none;
}

/* For mobile screens */
@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: flex !important;
  }
  .mobile-menu-overlay {
    display: flex !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.9); /* dark grey background */
    z-index: 1000;
    justify-content: flex-end;
  }
  .mobile-menu {
    background: #333; /* dark grey for mobile menu */
    width: 80%;
    max-width: 300px;
    height: 100%;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column; /* Stack content vertically */
    position: relative;
  }
}

.homepage-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .homepage-container {
    flex-direction: column;
  }
  .homepage-sidebar {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  .homepage-main {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}

.search-input {
  width: 100%;
  max-width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.dashboard-container {
  margin-bottom: 0px;
  padding: 0px;
  border: 0px solid #ddd;
  border-radius: 0px;
  min-width: 800px;
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .super-dashboard-container {
    max-width: 100% !important;
    width: 100%;
    box-sizing: border-box;
  }
  .container {
    max-width: 100% !important;
    width: 100%;
    box-sizing: border-box;
  }
  .left-column {
    max-width: 100% !important;
    width: 100%;
    box-sizing: border-box;
  }
  .dashboard-container {
    max-width: 100% !important;
    width: 100%;
    box-sizing: border-box;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 1024px;
  text-align: center;
}

.tools-dropdown:hover .dropdown-content {
  display: block !important;
  z-index: 9000;
}

@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  color: #333; /* Adjust color as needed */
  cursor: pointer;
  z-index: 10;
}

/* NEW FANTASY LEAGuE CSS */
/* Add your CSS below this line */


.page-container {
width: 100% !important;
  max-width: 1024px !important;
  background-color: "white" !important; 
  max-height: "1024px" !important;
  margin-top: "20px" !important;
  border-radius: "10px" !important; 
  height: "100%" !important;
  margin-bottom: "20px" !important;
}

.splash-container {
  text-align: center;
  padding: 20px;
  background: #222;
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 20px;
  padding: 20px;
}

.game-card {
  position: relative;
  height: auto;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.overlay {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.game-logo {
  max-width: 75%;
  margin-bottom: 10px;
}

.game-link {
  background: #c1d639;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.game-status {
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(196, 123, 123);
}

/* Header */

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #222;
  padding: 10px 20px;
  color: white;
}

.header-logo {
  width: 250px;
}

.header-nav {
  display: flex;
  gap: 15px;
}

.header-nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.dropdown {
  position: relative;
}

.dropdown-btn {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #333;
  padding: 10px;
  top: 100%;
  left: 0;
  z-index: 100;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.login-btn, .logout-btn {
  background: #13A1A8;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.mobile-menu-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  display: none;
}

@media (max-width: 768px) {
  .header-nav {
    display: none;
  }
  .mobile-menu-btn {
    display: block;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-menu {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}


.homepage-container {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-logo-container {
  margin-top: 20px;
}

.event-logo {
  width: 400px;
}

.rules-container {
  background: white;
  color: black;
  padding: 20px;
  padding-top: 0px;
  border-radius: 8px;
  max-width: 1024px;
  margin-top: 0px;
}

.actions-row {
  display: flex;
  background-color: #eee;
  justify-content: space-between;
  align-items: stretch; /* ✅ Ensure all boxes stretch to the same height */
  max-width: 1024px;
  width: 100%;
  margin-top: 20px;
  gap: 20px; /* ✅ Adds spacing between boxes */
}

.action-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* ✅ Pushes button to the bottom */
  flex: 1; /* ✅ Ensures all boxes take equal width */
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  min-height: 100%; /* ✅ Ensures all boxes match the tallest one */
}
.action-box img {
  max-width: 80px; /* ✅ Adjust size */
  height: auto;
  display: block;
  margin: 0 auto; /* ✅ Ensures centering horizontally */
}
.action-box h3 {
  flex-grow: 1; /* ✅ Allows the text to take up space */
  text-align: center;
  color: #333;
  margin-top: 10px;
  margin-bottom: 0px;
}

.action-box p {
  flex-grow: 1; /* ✅ Ensures paragraphs fill space and keep button at bottom */
  margin-top: 10px;
  text-align: center;
  color: #333;
}

.action-box button {
  align-self: center; /* ✅ Aligns button at the bottom */
  margin-top: auto; /* ✅ Pushes button down */
  width: 80%;
}

.team-requirements-container{
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  padding: 10px;
  color: #333;
}

.team-requirements{
display:flex; 
flex-direction:row ;
justify-content: space-between;
padding: 20px
}
.team-requirements div{
  padding-right: 20px 
}

.team-display, .mini-league  {
  text-align: center;
  background: white;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  max-width: 1024px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .team-display {
    padding: 10px; /* Reduce padding for smaller screens */
    margin-top: 10px;
  }

  .team-grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    overflow-x: auto; /* ✅ Enables horizontal scrolling */
    white-space: nowrap; /* ✅ Prevents line breaks */
    padding-bottom: 10px; /* ✅ Space for scrolling */
    width: 100%;
  }

  .team-member {
    flex: 0 0 auto; /* ✅ Prevents shrinking */
    width: 120px; /* ✅ Adjust for better mobile display */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .team-member img {
    width: 50px; /* Reduce image size */
    height: 50px;
    border-radius: 50%;
  }

  .racer-category {
    font-size: 0.7em; /* Smaller font for mobile */
  }

  .team-requirements-container{
    flex-direction: column; 
  }
  .team-requirements{
    flex-direction: column; 
    padding: 0px
  }
}


.team-member img {
  width: 60px;
  border-radius: 50%;
}

.country-flag {
  width: 20px;
  margin-top: 5px;
}

.action-icon {
 width: 120px;
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.login-box {
  background: white;
  padding: 20px;
  width: 80%;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}


/* Greeting Section */
.user-greeting {
  text-align: center;
  color: #222;
  margin-bottom: 20px;
  background-color: #ddd;
  padding: 20px;
  margin: 0px;
  border-radius: 10px;
}

.user-greeting h2 {
  font-size: 1.8em;
  margin: 5px 0;
}

.user-greeting p {
  font-size: 1.2em;
  color: #000;
}


user-stats {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  color: "#222";
}
/* Your Team Section */
.team-display {
  text-align: left;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 1024px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.racer-table {
color: #ddd;
background-color: black;
}
.racer-table td {
  color: #000;
  background-color: aqua;
  }
.racer-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.empty-slot {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #ddd; /* Grey placeholder */
  display: flex;
  align-items: center;
  justify-content: center;
}

.racer-category {
  font-size: 0.8em;
  font-weight: bold;
}

.country-flag {
  width: 20px;
  margin-top: 5px;
}

/* Team Display */
.team-display {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 1024px;
  margin-top: 20px;
}

.team-grid {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
}

.team-member {
  height: 170px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  text-align: center;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly !important;
}

.empty-slot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ddd; /* Grey Circle */
}

/* Racer Selection */
.racers-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 20px;
}

/* Filter Tabs */
.filter-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.filter-tabs button {
  padding: 8px 12px;
  border: none;
  background: #222;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.filter-tabs button.active {
  background: #ffcc00;
  color: black;
}

/* Bank Status */
.bank-status {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
  font-weight: bold;
}

/* Team select */

/* Team Selection Table */
.racers-table-container {
  margin-top: 20px;
  display: flex;
  background-color: #000;
  justify-content: center;
}

.racers-table {
  width: 100%;
  max-width: 1024px;
  border-collapse: collapse;
  text-align: left;
  background: black;
}

.racers-table th, .racers-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.racers-table th {
  background: #222;
  color: white;
}

.racers-table td {
  text-align: center;
}

.racer-portrait {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.select-button {
  background: #13A1A8;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

.select-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Filter Tabs */
.filter-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.filter-tabs button {
  padding: 8px 12px;
  border: none;
  background: #222;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.filter-tabs button.active {
  background: #e0e0e0;
  color: black;
}

.remove-cross {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 16px;
  color: rgb(66, 66, 66);
  cursor: pointer;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.remove-cross:hover {
  color: darkred;
}

.racer-thumb {
  position: relative; /* ✅ Ensures the cross is positioned inside */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: transparent;
  padding: 10px;
  border-radius: 8px;
  width: 100px;
  height: 120px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}
/* FOOTER */

.footer-container {
  background: #222;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-top {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  width: 100%;
}

.footer-column {
  width: 30%;
}

.footer-logo {
  width: 200px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
}

.footer-bottom nav a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.footer {
  background-color: #000;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer link{
    color: white;
}

.footer-container {
  background-color: #000 !important;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  line-height: 160%;
}

.footer h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: white;
}
/* Footer Links - Ensuring White Color */
.footer-column a,
.footer a, 
.footer a:visited, 
.footer a:hover, 
.footer a:active {
  color: #aaa !important;  /* Ensures all links stay white */
  text-decoration: none;  /* Removes underline */
}

.footer a:hover {
  color: #f4d03f !important; /* Optional: Yellow hover effect */
}

.game-status {
  font-weight: bold;
  margin-top: 5px;
}

.game-status.open {
  color: rgb(131, 200, 131);
}

.game-status.closed {
  color: #a4a8ac; /* Neutral gray for closed events */
}

.game-status.archived {
  color: a4a8ac;
}

.game-card.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

 .league-container {
  text-align: left;
  color: black;
  background-color: #eee;
  padding: 20px;
  border-radius: 8px;
  max-width: 1024px;
  margin-top: 20px;
}


.team-container {
  text-align: left;
  color: black;
  padding: 20px;
  border-radius: 8px;
  max-width: 1024px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  /* Ensure containers fit mobile screens */
  .page-container {
    width: 95% !important;
    max-width: none !important;
    margin: 10px auto !important;
    padding: 15px;
  }

  /* Adjust Header */
  .header-container {
    padding: 10px 15px;
    flex-direction: row; /* Stack logo and navigation */
    align-items: center;
  }

  /* Fix Navigation */


  /* Improve Footer Layout */
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  /* Fix Buttons */
  .secondary-btn,
  .primary-btn {
    width: 100%; /* Make buttons full width on mobile */
    font-size: 1em;
    padding: 12px;
  }

  /* Improve Tables */
  .ranking-table {
    font-size: 0.9em;
  }

  .ranking-table th,
  .ranking-table td {
    padding: 6px;
  }

  /* Adjust League Display */
  .league-container {
    padding: 15px;
    font-size: 0.9em;
  }

  /* Fix Racer Display */
  .racer-thumb {
    width: 80px;
    height: auto;
  }

  .racer-thumb img {
    width: 50px;
    height: 50px;
  }
}
