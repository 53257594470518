body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-container, .footer-container {
  width: 100%;
  background-color: #333;
  color: #fff;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 100px;
  box-sizing: border-box;
}

.footer-container {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}



.video-map-column {
  flex: 1;
  margin-right: 20px;
}

.youtube-url-input {
  margin-bottom: 20px;
}

.navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navigation li {
  margin-left: 20px;
}

.navigation a {
  color: #fff;
  text-decoration: none;
}

.drop-area {
  border: 3px dashed #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  text-align: center;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.drop-area:hover {
  border-color: #999;
  background-color: #f5f5f5;
}

.drop-area.dragover {
  border-color: #333;
  background-color: #eee;
  transform: scale(1.02);
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.drop-area p {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 15px;
}

.drop-area input {
  display: none;
}
.telemetry-drop-area, .video-drop-area {
  border: 3px dashed #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  text-align: center;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.telemetry-drop-area:hover, .video-drop-area:hover {
  border-color: #999;
  background-color: #f5f5f5;
}

.telemetry-drop-area.dragover, .video-drop-area.dragover {
  border-color: #333;
  background-color: #eee;
}

.telemetry-drop-area p, .video-drop-area p {
  font-size: 1em;
  color: #666;
  margin-bottom: 8px;
}

/* Add these styles to your CSS */
.container {
  flex: 1;
  padding: 20px;
  max-width: 1800px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

.left-column, .right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 0;
}

.video-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.map-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#map {
  height: 400px;
  width: 100%;
}

.video-player {
  width: 100%;
  height: 400px;
}

.drop-area {
  border: 3px dashed #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.drop-area:hover {
  border-color: #999;
  background-color: #f5f5f5;
}

.drop-area.dragover {
  border-color: #333;
  background-color: #eee;
}

.drop-area p {
  font-size: 1em;
  color: #666;
  margin-bottom: 8px;
}

.youtube-url-input {
  margin: 10px 0;
  display: flex;
  gap: 10px;
}

.youtube-url-input input {
  flex: 1;
  padding: 5px;
}

.graphs {
  width: 100%;
  margin-bottom: 20px;
}

.metrics {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.metrics ul {
  list-style: none;
  padding: 0;
}

.metrics li {
  margin-bottom: 8px;
}

.footer-container {
  margin-top: auto;
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
}